import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
// import Hero from '../../components/Hero';
import Icon from '../../components/Icon';

const ScienceAtHome = () => (
  <Layout title="Science at Home" className="science-starters">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-purple"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="/science-starters/getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb active">Science at Home</li>
        </ul>
        <h1 className="page-title">Science at Home</h1>
        <Image filename="hero-science-at-home.png" className="hero-image" />
      </div>
    </div>
    {/* Science at Home Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h4>
              These simple, ready-to-use experiments utilize common household
              items to reinforce core scientific principles. Each experiment
              includes information on how to include them in your classroom or
              distance learning curriculum and are taught by 3M scientists and
              special guests.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Science at Home Grid */}
    <Section className="science-at-home__cards mt-3">
      <Container>
        <Row>
          {/* Marshmallow Tower */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MarshmellowTower-Thumbnail.png" />
              <div className="card__content">
                <h3>Marshmallow Tower</h3>
                <h4>Have you ever wondered how skyscrapers can be so tall?</h4>
                <p>
                  Or how people build bridges to span long distances? Explore
                  engineering techniques to build sturdy structures using only
                  marshmallows and uncooked spaghetti.
                </p>
              </div>
              <Link
                to="marshmallow-tower"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* C02 Balloon */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="C02Balloon-Thumbnail.png" />
              <div className="card__content">
                <h3>
                  CO<sub>2</sub> Balloon
                </h3>
                <h4>
                  Baking soda and acid reactions in baking make things puff and
                  rise.
                </h4>
                <p>
                  But how could you use this same chemical reaction to blow up a
                  balloon?
                </p>
              </div>
              <Link to="c2-balloon" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Elephant Toothpaste */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="ElephantToothpaste-Thumbnail.png" />
              <div className="card__content">
                <h3>Elephant Toothpaste</h3>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she shows you how to make a chemical reaction so big
                  it’s fit for elephants!
                </p>
              </div>
              <Link
                to="elephant-toothpaste"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
        <Row>
          {/* Rainbow Bubble Snake */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="RainbowSnake-Thumbnail.png" />
              <div className="card__content">
                <h3>Rainbow Bubble Snake</h3>
                <h4>
                  Everyone loves bubbles, but have you ever thought about how
                  they form?
                </h4>
                <p>
                  In this video, special guest, Kate the Chemist, shows you the
                  science behind bubbles. With just a few simple materials,
                  you’ll learn how to make a bright and colorful bubble snake
                  using your breath, soap, water and a plastic water bottle.
                </p>
              </div>
              <Link
                to="rainbow-bubble-snake"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Fidget Spinner */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FidgetSpinner-Thumbnail.png" />
              <div className="card__content">
                <h3>Fidget Spinner</h3>
                <h4>
                  Your teacher might not love when you have one in class, but
                  the physics behind fidget spinners are truly head-spinning!
                </h4>
                <p>
                  Join 3M scientist Tesha R.-Alston Dampier as she shows you how
                  a spinning motion changes the way things move.
                </p>
              </div>
              <Link to="fidget-spinner" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Liquid Fireworks */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="LiquidFireworks-Thumbnail.png" />
              <div className="card__content">
                <h3>Liquid Fireworks</h3>
                <p>
                  Join 3M scientist Jeff Payne as he uses nothing more than
                  milk, dish soap, and a few other kitchen supplies to get the
                  amazing effects of fireworks without using any fire at all.
                </p>
              </div>
              <Link to="liquid-fireworks" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
        <Row>
          {/* Soap Boat */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="SoapBoat-Thumbnail.png" />
              <div className="card__content">
                <h3>Soap Boat</h3>
                <h4>
                  Join 3M Researcher Vasav Shani as he introduces you to the
                  science of surface tension.
                </h4>
                <p>
                  Not only is it only important  for many engineering and earth
                  science processes, it also makes blowing bubbles possible.
                </p>
              </div>
              <Link to="soap-boat" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* How Do We Breathe? */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="HowDoWeBreathe-Thumbnail.png" />
              <div className="card__content">
                <h3>How Do We Breathe?</h3>
                <h4>How do our lungs work?</h4>
                <p>
                  Follow along as special guest Dakota Dozier, an offensive
                  lineman for the Minnesota Vikings, makes a model to show how
                  air flows in and out of the lungs with ease.
                </p>
              </div>
              <Link
                to="how-do-we-breathe"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Bernoulli Balance */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="BernoulliBalance-Thumbnail.png" />
              <div className="card__content">
                <h3>Bernoulli Balance</h3>
                <h4>
                  A jetliner can weigh over 300 tons, so why doesn’t it just
                  drop out of the air?
                </h4>
                <p>
                  3M’s SVP of Corporate Affairs, Denise Rutherford explains the
                  Bernoulli principle and how without it, planes (and birds)
                  couldn’t fly.
                </p>
              </div>
              <Link
                to="bernoulli-balance"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
        <Row>
          {/* Push and Pull */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="PushAndPull-Thumbnail.png" />
              <div className="card__content">
                <h3>Push and Pull</h3>
                <p>
                  Follow along with 3M’s Sam Reiss, as he shows you that
                  magnetism is more than just a simple push and pull – it’s an
                  example of the power of the earth itself.
                </p>
              </div>
              <Link to="push-and-pull" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Diffusion with Miss America 2020 */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="DiffusionWithMiss-Thumbnail.png" />
              <div className="card__content">
                <h3>Diffusion with Miss America 2020</h3>
                <h4>
                  Ever wonder why things mix (or don’t mix) differently in
                  different temperatures of water?
                </h4>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she explains diffusion and how substances move though
                  water.
                </p>
              </div>
              <Link
                to="diffusion-with-miss-america-2020"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Feeling Sound */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FeelingSound-Thumbnail.png" />
              <div className="card__content">
                <h3>Feeling Sound</h3>
                <p>
                  Believe it or not, you can feel sound! Join Gitanjali Rao,
                  former Discovery Education 3M Young Scientist Challenge
                  winner, as she teaches about the frequency of sound and how we
                  perceive pitch.
                </p>
              </div>
              <Link to="feeling-sound" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
        <Row>
          {/* Inflation Station */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="InflationStation-Thumbnail.png" />
              <div className="card__content">
                <h3>Inflation Station</h3>
                <p>
                  Follow along with 3M’s Chief Science Advocate, Jayshree Seth,
                  as she teaches students how chemistry can help put some air
                  where it’s most needed!
                </p>
              </div>
              <Link
                to="inflation-station"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Make Your Own Cotton-Ball Launcher */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MakeYourOwnCotton-Thumbnail.png" />
              <div className="card__content">
                <h3>Make Your Own Cotton-Ball Launcher</h3>
                <h4>
                  What can a rubber band and a cotton ball teach you about
                  potential and kinetic energy?
                </h4>
                <p>
                  Join Michael Lewandowski to make your own cotton-ball launcher
                  and find out!
                </p>
              </div>
              <Link
                to="make-your-own-cotton-ball-launcher"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default ScienceAtHome;
